export default [
  {
    path: '/payout/bank/account',
    name: 'payout-bank-account',
    component: () => import('@/views/bank/PayoutBankAccount.vue'),
  },
  {
    path: '/usdt/history',
    name: 'usdt-history',
    component: () => import('@/views/order-management/Usdt.vue'),
  },
]
