import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
  },
  actions: {
    // ------------------------------------------------
    // Master Actions
    // ------------------------------------------------

    // ------------------------------------------------
    // merchant Actions
    // ------------------------------------------------
    fetchMerchant(ctx, payload) {
      const {
        search,
      } = payload
      let q = []
      if (search) {
        q = [
          `email:${search}`,
          `name:${search}`,
          `merchant_code:${search}`,
          `merchant_key:${search}`,
          `username:${search}`,
        ]
      }
      return new Promise((resolve, reject) => {
        axios
          .get('/merchants', { params: { ...payload, search: q.length > 0 ? q.join(';') : null } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchMerchantDetail(ctx, { merchantId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/merchants/${merchantId}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updateMerchant(ctx, {
      merchantId, feeConfig, status, forControlCard, ips, payoutLimit, member_fee_config, usdt_out_fee,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/merchants/${merchantId}`, {
            feeConfig, status, forControlCard, ips, payoutLimit, member_fee_config, usdt_out_fee,
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addMoneyManual(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('transaction/add-money-manual', {
            ...data,
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/wishlist', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
