export default [
  {
    path: '/payin/bank/account',
    name: 'pay-in-bank-account',
    component: () => import('@/views/bank/PayinBankAccount.vue'),
  },
  {
    path: '/payin/bank/history',
    name: 'pay-in-bank-history',
    component: () => import('@/views/bank/BankAccountTransactions.vue'),
  },
  {
    path: '/payin/history',
    name: 'payin-history',
    component: () => import('@/views/order-management/PayinHistory.vue'),
  },
]
