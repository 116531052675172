// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_BANK_URL,
  timeout: 1000 * 60 * 2, // Timeout
  // headers: {'X-Custom-Header': 'foobar'}
})

export default axiosIns
