export default [
  {
    path: '/merchant-management/account',
    name: 'merchant-management-account',
    component: () => import('@/views/merchant-management/Account.vue'),
  },
  {
    path: '/merchant-management/account/:id',
    name: 'merchant-management-account',
    component: () => import('@/views/merchant-management/EditAccount.vue'),
  },
  {
    path: '/merchant-management/order/balance-history',
    name: 'merchant-balance-change',
    component: () => import('@/views/order-management/BalanceHistory.vue'),
  },
  {
    path: '/withdrawal-management',
    name: 'withdrawal-management',
    component: () => import('@/views/withdraw/ListWithdraw.vue'),
  },
]
