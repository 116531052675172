import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
  },
  actions: {
    // ------------------------------------------------
    // Master Actions
    // ------------------------------------------------
    fetchMerchantMaster(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/merchant/get-merchants-for-filter-route', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchMerchantParentMaster(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/get-all-parent-merchants', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchPayGateMaster(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pay-order/pay-gates', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Order Actions
    // ------------------------------------------------
    fetchPayin(ctx, {
      searchKeyWord, dateFrom, dateTo, merchantId, payGateId, status, page, payinType, isHistory,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payins', {
            params: {
              q: searchKeyWord ?? null,
              orderBy: 'created_at',
              sortedBy: 'desc',
              page,
              limit: 100,
              dateFrom,
              dateTo,
              merchantId,
              payGateId,
              status,
              payinType,
              isHistory,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchStatistic(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/general-analyze', {
            params: {
              type,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    callbackCustomer(ctx, { id, status }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/order/re-callback-to-merchant', { id, status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeOrderStatusPayout(ctx, { orderId, code, message }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/payout-manually', { orderId, code, message })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBalanceHistory(ctx, args) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pay-orders', { params: { ...args, limit: 100 } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Payout Actions
    // ------------------------------------------------
    fetchPayout(ctx, {
      searchKeyWord, dateFrom, dateTo, merchantId, payGateId, status, page,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payouts', {
            params: {
              q: searchKeyWord ?? null,
              orderBy: 'created_at',
              sortedBy: 'desc',
              page,
              limit: 100,
              dateFrom,
              dateTo,
              merchantId,
              payGateId,
              status,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
  },
}
