import axios from '@/libs/bank-axios'
import originalAxios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // ------------------------------------------------
    // merchant Actions
    // ------------------------------------------------
    fetchBankAccount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/bank-account', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchBankAccountDetail(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bank-account/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchBankMaster(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/bank-account/master-list', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchBankAccountTransactions(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/bank-history', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchBankPayout(ctx, payload) {
      return new Promise((resolve, reject) => {
        originalAxios
          .get('/banks?search=type:payout_auto', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchBankAccountHistoryPayout(ctx, payload) {
      return new Promise((resolve, reject) => {
        originalAxios
          .get('/bank-history', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    activeBankAccount(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/bank-account/active/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    deActiveBankAccount(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/bank-account/inactive/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    deleteBankAccount(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/bank-account/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updateBankAccount(ctx, { id, account }) {
      return new Promise((resolve, reject) => {
        const headers = { 'Content-Type': 'multipart/form-data' }
        axios
          .patch(`/bank-account/${id}`, account, { headers })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBankAccount(ctx, payload) {
      const headers = { 'Content-Type': 'multipart/form-data' }
      return new Promise((resolve, reject) => {
        axios
          .post('/bank-account', payload, { headers })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStatisticBankAccount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/bank-account/statistics', { params: payload })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
  },
}
