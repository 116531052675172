import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import orderManager from '@/views/order-management/oderStoreModule'
import merchantManager from '@/views/merchant-management/merchantStoreModule'
import bankStoreModule from '@/views/bank/bankStoreModule'
import withdrawStoreModule from '@/views/withdraw/withdrawStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'order-management': orderManager,
    'merchant-management': merchantManager,
    'bank-manager': bankStoreModule,
    'withdraw-manager': withdrawStoreModule,
  },
  strict: process.env.DEV,
})
