export default [
  {
    path: '/order-management/pay-in',
    name: 'order-management',
    component: () => import('@/views/order-management/Payin.vue'),
  },
  {
    path: '/order-management/pay-out',
    name: 'order-management',
    component: () => import('@/views/order-management/Payout.vue'),
  },
]
